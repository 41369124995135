<template>
  <div :class="['notification-wrap p-8 pl-12 pr-24 rounded flex', notificationClass]" role="alert">
    <strong class="font-semibold text-white">{{ notificationType }} </strong>

    <div class="ml-4">
      <p
        v-for="(message, index) in messages"
        :key="index"
        :class="['text-white', { 'mb-4': index !== messages.length - 1 }]"
      >
        {{ message }}
      </p>
    </div>

    <span
      class="absolute top-0 bottom-0 right-0 pt-5 pr-5 text-white"
      :class="{ 'hover:text-red-100': isError, 'hover:text-orange-100': isWarning, 'hover:text-green-200': isSuccess }"
      @click="hideNotification"
    >
      <svg class="fill-current h-24 w-24 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ElNotification',

  computed: {
    ...mapState({
      type: state => state.notification.type,
      message: state => state.notification.message
    }),

    messages () {
      if (Array.isArray(this.message)) {
        return this.message
      }

      return [this.message]
    },

    isError () {
      return this.type === 'error'
    },

    isWarning () {
      return this.type === 'warning'
    },

    isSuccess () {
      return this.type === 'success'
    },

    notificationType () {
      if (this.isError) return 'Error:'
      if (this.isWarning) return 'Warning:'
      if (this.isSuccess) return 'Success:'
      return ''
    },

    notificationClass () {
      if (this.isError) return 'bg-red'
      if (this.isWarning) return 'bg-orange'
      if (this.isSuccess) return 'bg-green'
      return ''
    }
  },

  methods: {
    ...mapActions(['hideNotification'])
  }
}
</script>

<style lang="scss" scoped>
.notification-wrap {
  @apply fixed max-w-md w-full z-20;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
